import { render, staticRenderFns } from "./addUpdate.vue?vue&type=template&id=793f7bdb&scoped=true"
import script from "./addUpdate.vue?vue&type=script&lang=js"
export * from "./addUpdate.vue?vue&type=script&lang=js"
import style0 from "./addUpdate.vue?vue&type=style&index=0&id=793f7bdb&prod&lang=scss&scoped=road_info_management"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793f7bdb",
  null
  
)

export default component.exports