<template>
	<div class="road_bill_add_update">
		<jy-dialog :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'1000px'" append-to-body>
			<el-form :model="formData" ref="dialogForm" label-width="110px" :rules="formRules">
				<el-row>
					<el-col :span="12">
						<el-form-item label="日期" prop="oDate">
							<el-date-picker v-model="formData.oDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="线路" prop="routeId">
							<el-select v-model="formData.routeId" placeholder="请选择" filterable>
								<el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="formData.operationT == '0'">
						<el-form-item label="起始站" prop="sSiteId">
							<el-select v-model="formData.sSiteId" @change="setSiteNa">
								<el-option v-for="one in siteList" v-bind:key="one.siteId" :label="one.cName" :value="one.siteId"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="formData.operationT == '0'">
						<el-form-item label="终点站" prop="eSiteId">
							<el-select v-model="formData.eSiteId" @change="setSiteNa">
								<el-option v-for="one in siteList" v-bind:key="one.siteId" :label="one.cName" :value="one.siteId"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="发车时间" prop="sTime">
							<el-time-picker
								v-model="formData.sTime"
								format="HH:mm"
								value-format="HH:mm"
								:picker-options="pickerOptions"
								placeholder="任意时间点"
							>
							</el-time-picker>
						</el-form-item>
					</el-col>
					<!-- <el-col :span="12">
                        <el-form-item label="开始时间" prop="sTime">
                            <el-time-picker v-model="formData.sTime" value-format="HH:mm:ss" :picker-options="pickerOptions" placeholder="任意时间点">
                            </el-time-picker>
                        </el-form-item>
                    </el-col> -->
					<el-col :span="12">
						<el-form-item label="结束时间" prop="eTime">
							<el-time-picker
								v-model="formData.eTime"
								format="HH:mm"
								value-format="HH:mm"
								:picker-options="pickerOptions"
								placeholder="任意时间点"
							>
							</el-time-picker>
						</el-form-item>
					</el-col>
					<!-- <el-col :span="12">
                        <el-form-item label="运行情况" prop="eState">
                            <el-select v-model="formData.eState">
                                <el-option v-for="item in runStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col> -->
					<el-col :span="12">
						<el-form-item label="运行里程" prop="runM">
							<el-input v-model="formData.runM"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="车辆" prop="vehicleId">
							<el-select v-model="formData.vehicleId" placeholder="请选择" filterable>
								<el-option
									v-for="item in vehicleList"
									:key="item.vehicleId"
									:label="item.plateNum"
									:value="item.vehicleId"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="司机" prop="driverId">
							<el-select v-model="formData.driverId" placeholder="请选择" @change="setDriverNa">
								<el-option v-for="item in driverList" :key="item.driverId" :label="item.cName" :value="item.driverId"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formData.operationT == '0'">
					<el-col :span="12">
						<el-form-item label="跨线路">
							<el-radio-group v-model="formData.isCrossL">
								<el-radio :label="'1'">否</el-radio>
								<el-radio :label="'2'">是</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="formData.isCrossL == '2'">
						<el-form-item label="车辆所属线路" prop="cRouteId">
							<el-select v-model="formData.cRouteId" placeholder="请选择" filterable>
								<el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="营运状态">
							<el-radio-group v-model="formData.operationT">
								<el-radio :label="'0'">营运</el-radio>
								<el-radio :label="'1'">非营运</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-if="formData.operationT == '1'">
						<el-form-item label="业务类型" prop="businessT">
							<el-select v-model="formData.businessT" placeholder="请选择">
								<el-option v-for="item in dreportTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formData.operationT == '1'">
					<el-col :span="12">
						<div class="map_select_box">
							<div>
								<el-form-item label="开始经度:" prop="sLng">
									<el-input v-model="formData.sLng" disabled></el-input>
								</el-form-item>
								<el-form-item label="开始纬度:" prop="sLat">
									<el-input v-model="formData.sLat" disabled></el-input>
								</el-form-item>
							</div>
							<div>
								<el-button type="primary" plain v-on:click="selectStartMap">选择经纬度</el-button>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="map_select_box">
							<div>
								<el-form-item label="结束经度:" prop="eLng">
									<el-input v-model="formData.eLng" disabled></el-input>
								</el-form-item>
								<el-form-item label="结束纬度:" prop="eLat">
									<el-input v-model="formData.eLat" disabled></el-input>
								</el-form-item>
							</div>
							<div>
								<el-button type="primary" plain v-on:click="selectEndMap">选择经纬度</el-button>
							</div>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="备注">
							<el-input v-model="formData.remark" type="textarea" rows="3"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<template #footer>
				<el-button v-on:click="dialogVisible = false">取消</el-button>
				<el-button type="primary" v-on:click="confimFun">确定</el-button>
			</template>
		</jy-dialog>
		<map-select ref="mapSelect" @getLngLat="getLngLat"></map-select>
	</div>
</template>
<script>
import { getRunStatus } from "@/common/js/wordbook.js";
import { dreportType } from "@/common/js/wordbook.js";
import mapSelect from "@/components/pages/admin/basicInformation/siteManagement/mapSelect.vue";
const pickerOptions = {};
let formRules = {
	oDate: [
		{
			required: true,
			message: "请选择日期",
			trigger: "change"
		}
	],
	routeId: [
		{
			required: true,
			message: "请选择线路",
			trigger: "change"
		}
	],
	sSiteId: [
		{
			required: true,
			message: "请选择起始站",
			trigger: "change"
		}
	],
	eSiteId: [
		{
			required: true,
			message: "请选择终点站",
			trigger: "change"
		}
	],
	sTime: [
		{
			required: true,
			message: "请选择发车时间",
			trigger: "change"
		}
	],
	eTime: [
		{
			required: true,
			message: "请选择结束时间",
			trigger: "change"
		}
	],
	runM: [
		{
			required: true,
			message: "请输入运行里程",
			trigger: "blur"
		}
	],
	cRouteId: [
		{
			required: true,
			message: "请选择车辆所属线路",
			trigger: "change"
		}
	],
	vehicleId: [
		{
			required: true,
			message: "请选择车辆",
			trigger: "change"
		}
	],
	driverId: [
		{
			required: true,
			message: "请选择司机",
			trigger: "change"
		}
	]
};
export default {
	data() {
		let runStatus = getRunStatus();
		var validateETime = (rule, value, callback) => {
			if (this.formData.sTime != "" && value != "") {
				if (value < this.formData.sTime) {
					callback(new Error("结束时间不能小于发车时间"));
				} else {
					callback();
				}
			}
		};
		return {
			canWatch: true,
			type: 0,
			dialogVisible: false,
			routeList: [],
			siteList: [],
			driverList: [],
			vehicleList: [],
			dreportTypeList: [],
			formData: {
				oDate: "",
				orgId: "",
				orgPath: "",
				routeId: "",
				routeNa: "",
				sSiteId: "",
				sSiteNa: "",
				eSiteId: "",
				eSiteNa: "",
				dTime: "",
				sTime: "",
				eTime: "",
				vehicleId: "",
				vehicleNo: "",
				customNo: "",
				driverId: "",
				driverNa: "",
				workNum: "",
				eState: "", //执行状态（0-未发车 1-已发车 2-已完成）
				rType: "1",
				runM: "",
				isCrossL: "1",
				cRouteId: "",
				cRouteNa: "",
				remark: "",
				operationT: "0",
				businessT: "",
				sLat: "",
				sLng: "",
				eLat: "",
				eLng: ""
			},
			formRules,
			validateETime,
			runStatus,
			pickerOptions
		};
	},
	computed: {
		title: function () {
			return this.type == 0 ? "路单补录" : "路单修改";
		}
	},
	components: {
		mapSelect
	},
	watch: {
		dialogVisible(val) {
			if (!val) {
				this.$refs.dialogForm.resetFields();
				this.formData = {
					oDate: "",
					orgId: "",
					orgPath: "",
					routeId: "",
					routeNa: "",
					sSiteId: "",
					sSiteNa: "",
					eSiteId: "",
					eSiteNa: "",
					dTime: "",
					sTime: "",
					eTime: "",
					vehicleId: "",
					vehicleNo: "",
					customNo: "",
					driverId: "",
					driverNa: "",
					workNum: "",
					eState: "", //执行状态（0-未发车 1-已发车 2-已完成）
					rType: "1",
					runM: "",
					isCrossL: "1",
					cRouteId: "",
					cRouteNa: "",
					remark: "",
					operationT: "0",
					businessT: "",
					sLat: "",
					sLng: "",
					eLat: "",
					eLng: ""
				};
			}
		},
		"formData.routeId": function (val) {
			if (this.canWatch) {
				this.siteList = [];
				this.formData.sSiteId = "";
				this.formData.sSiteNa = "";
				this.formData.eSiteId = "";
				this.formData.eSiteNa = "";
			}
			this.setRouteNa();
			if (this.formData.isCrossL == "1") {
				if (this.canWatch) {
					this.formData.vehicleId = "";
					this.formData.vehicleNo = "";
					this.formData.customNo = "";
					this.vehicleList = [];
					this.driverList = [];
					this.formData.driverId = "";
					this.formData.driverNa = "";
					this.formData.workNum = "";
				}
				if (val) {
					this.getVehicles(val);
				}
			}
			if (val) {
				this.getSiteList();
			}
		},
		"formData.vehicleId": function (val) {
			if (this.canWatch) {
				this.driverList = [];
				this.formData.driverId = "";
				this.formData.driverNa = "";
				this.formData.workNum = "";
			}
			this.setVehicleNo();
			if (val) {
				this.getDriverList(val);
			}
		},
		"formData.isCrossL": function (val) {
			if (this.canWatch) {
				this.formData.vehicleId = "";
				this.formData.vehicleNo = "";
				this.formData.customNo = "";
				this.vehicleList = [];
				this.driverList = [];
				this.formData.driverId = "";
				this.formData.driverNa = "";
				this.formData.workNum = "";
			}
			if (val == "1") {
				if (this.canWatch) {
					this.formData.cRouteId = "";
					this.formData.cRouteNa = "";
				}
				if (this.formData.routeId) {
					this.getVehicles(this.formData.routeId);
				}
			}
		},
		"formData.cRouteId": function (val) {
			this.setcRouteNa();
			if (this.formData.isCrossL == "2") {
				if (this.canWatch) {
					this.formData.vehicleId = "";
					this.formData.vehicleNo = "";
					this.formData.customNo = "";
					this.vehicleList = [];
					this.driverList = [];
					this.formData.driverId = "";
					this.formData.driverNa = "";
					this.formData.workNum = "";
				}
				if (val) {
					this.getVehicles(val);
				}
			}
		}
	},
	created() {
		this.getRouteList();
	},
	mounted() {
		let valid = {
			validator: this.validateETime,
			trigger: "blur"
		};
		this.dreportTypeList = dreportType();
		this.formRules.eTime.push(valid);
		console.log(this.formRules);
	},
	methods: {
		init(option, type) {
			this.dialogVisible = true;
			this.type = type || 0;
			this.$nextTick(() => {
				if (type > 0) {
					this.canWatch = false;
					Object.keys(this.formData).forEach(key => {
						this.formData[key] = option[key];
					});
					this.formData.waybillId = option.waybillId;
					this.$nextTick(() => {
						this.canWatch = true;
					});
				}
			});
		},
		confimFun() {
			this.$refs.dialogForm.validate(valid => {
				if (valid) {
					let url = "/waybillActual/";
					if (this.type == 0) {
						url += "add";
					} else {
						url += "update";
					}
					let params = {
						...this.formData
					};
					Object.keys(params).forEach(key => {
						if (params[key] === undefined) {
							delete params[key];
						}
					});
					this.$http.post(url, params).then(data => {
						this.$message({
							message: data.msg,
							type: "success"
						});
						this.$emit("updateList");
						this.dialogVisible = false;
					});
				} else {
					return false;
				}
			});
		},
		getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
			});
		},
		getSiteList() {
			let url = "/site/info/queryByRouteId";
			let params = {
				routeId: this.formData.routeId
			};
			this.$http.post(url, params).then(({ detail }) => {
				let list = detail.map(item => {
					return {
						...item,
						...item.routeSite,
						...item.siteInfo
					};
				});
				this.siteList = list;
			});
		},
		getVehicles(routeId) {
			if (!routeId) {
				return;
			}
			let url = "/base/vehicle/getVehiclesByOrgId";
			let params = {
				orgId: routeId
			};
			this.$http.post(url, params).then(({ detail }) => {
				this.vehicleList = detail;
			});
		},
		getDriverList(vehicleId) {
			let url = "/base/vehicle/driver/queryByVehicleId";
			let params = {
				vehicleId
			};
			this.$http.post(url, params).then(({ detail }) => {
				this.driverList = detail.map(one => one.driverInfo);
			});
		},
		setSiteNa() {
			if (this.siteList.length) {
				this.formData.sSiteNa = "";
				this.formData.eSiteNa = "";
			}
			this.siteList.forEach(one => {
				if (this.formData.sSiteId == one.siteId) {
					this.formData.sSiteNa = one.cName;
				}
				if (this.formData.eSiteId == one.siteId) {
					this.formData.eSiteNa = one.cName;
				}
			});
		},
		setRouteNa() {
			if (this.routeList.length) {
				this.formData.routeNa = "";
				this.formData.orgPath = "";
				this.formData.orgId = "";
			}
			this.routeList.forEach(one => {
				if (one.routeId == this.formData.routeId) {
					this.formData.routeNa = one.cName;
					this.formData.orgId = one.orgId;
					this.formData.orgPath = one.orgPath;
				}
			});
		},
		setcRouteNa() {
			this.routeList.forEach(one => {
				if (one.routeId == this.formData.cRouteId) {
					this.formData.cRouteNa = one.cName;
				}
			});
		},
		setVehicleNo() {
			if (this.vehicleList.length) {
				this.formData.vehicleNo = "";
				this.formData.customNo = "";
			}
			this.vehicleList.forEach(one => {
				if (one.vehicleId == this.formData.vehicleId) {
					this.formData.vehicleNo = one.plateNum;
					this.formData.customNo = one.customNo;
				}
			});
		},
		setDriverNa() {
			if (this.driverList.length) {
				this.formData.driverNa = "";
				this.formData.workNum = "";
			}
			this.driverList.forEach(one => {
				if (one.driverId == this.formData.driverId) {
					this.formData.driverNa = one.cName;
					this.formData.workNum = one.wNum;
				}
			});
		},
		selectStartMap() {
			if (this.formData.sLng && this.formData.sLat) {
				let newLngLat = this.$util.wgs84tobd09(Number(this.formData.sLng), Number(this.formData.sLat));
				var option = {
					lng: newLngLat[0],
					lat: newLngLat[1]
				};
				this.$refs.mapSelect.init(option, 1);
			} else {
				this.$refs.mapSelect.init("", 1);
			}
		},
		selectEndMap() {
			if (this.formData.eLng && this.formData.eLat) {
				let newLngLat = this.$util.wgs84tobd09(Number(this.formData.eLng), Number(this.formData.eLat));
				var option = {
					lng: newLngLat[0],
					lat: newLngLat[1]
				};
				this.$refs.mapSelect.init(option, 2);
			} else {
				this.$refs.mapSelect.init("", 2);
			}
		},
		getLngLat(lng, lat, type) {
			let newLngLat = this.$util.bd09towgs84(lng * 1, lat * 1);
			if (type == 1) {
				this.formData = {
					...this.formData,
					sLng: newLngLat[0],
					sLat: newLngLat[1]
				};
				this.$nextTick(() => {
					this.$refs.dialogForm.validateField(["sLng", "sLat"]);
				});
			} else {
				this.formData = {
					...this.formData,
					eLng: newLngLat[0],
					eLat: newLngLat[1]
				};
				this.$nextTick(() => {
					this.$refs.dialogForm.validateField(["eLng", "eLat"]);
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped="road_info_management">
.map_select_box {
	display: flex;
	align-items: center;

	> div {
		&:nth-of-type(1) {
			flex: 1;
		}

		&:nth-of-type(2) {
			margin-left: 20px;
		}
	}
}
</style>
