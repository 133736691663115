<template>
	<div class="road_info_management">
		<jy-query ref="checkForm" :model="formData">
			<jy-query-item label="日期" prop="oDate">
				<el-date-picker v-model="formData.oDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
			</jy-query-item>
			<jy-query-item label="自编号" prop="customNo">
				<el-input placeholder="请输入" v-model="formData.customNo"></el-input>
			</jy-query-item>
			<jy-query-item label="车牌号" prop="vehicleNo">
				<el-input placeholder="请输入" v-model="formData.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item label="司机" prop="driverNa">
				<el-input placeholder="请输入" v-model="formData.driverNa"></el-input>
			</jy-query-item>
			<jy-query-item label="运行情况" prop="eState">
				<el-select placeholder="请选择" v-model="formData.eState">
					<el-option v-for="item in runStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="审核状态" prop="verify">
				<el-select placeholder="请选择" v-model="formData.verify">
					<el-option v-for="item in auditStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="线路" prop="routeIds">
				<el-select v-model="formData.routeIds" placeholder="请选择" multiple filterable>
					<el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="路单类型" prop="sourceT">
				<el-select v-model="formData.sourceT" placeholder="请选择">
					<el-option v-for="item in routeTypeList" :key="item.dataType" :label="item.name" :value="item.dataType"></el-option>
				</el-select>
			</jy-query-item>
			<template #search>
				<el-button type="primary" v-on:click="search" v-if="btnexist('scheduleRoadBillInfoManagementList')">查询</el-button>
				<el-button type="primary" plain v-on:click="reset" v-if="btnexist('scheduleRoadBillInfoManagementReset')">重置</el-button>
				<!-- <el-row> </el-row> -->
			</template>
			<template slot="operate">
				<el-button type="primary" v-on:click="addFun" v-if="btnexist('scheduleRoadBillInfoManagementInsert')">补录</el-button>
				<el-button type="primary" v-on:click="batchDelete" v-if="btnexist('scheduleRoadBillInfoManagementBatchDelete')"
					>批量删除</el-button
				>
				<el-button type="primary" v-if="btnexist('scheduleRoadBillInfoManagementExport')">导出</el-button>
			</template>
		</jy-query>
		<jy-table max-height="541" ref="jyTable" @select-all="selectallchange" @select="selectchange" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" fixed align="center"></jy-table-column>
			<jy-table-column type="selection" width="40" fixed></jy-table-column>
			<jy-table-column prop="oDate" label="日期" width="100" fixed></jy-table-column>
			<jy-table-column prop="routeNa" label="线路名称" min-width="120" fixed></jy-table-column>
			<jy-table-column prop="customNo" label="自编号" width="120" show-overflow-tooltip fixed></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" width="120" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="driverNa" label="司机" width="160" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="sSiteNa" label="起点站" width="200" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="eSiteNa" label="终点站" width="200" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="sTime" label="发车时间" width="80"></jy-table-column>
			<jy-table-column prop="eTime" label="结束时间" width="80"></jy-table-column>
			<jy-table-column prop="runM" label="实际运行里程(km)" width="160" show-overflow-tooltip></jy-table-column>
			<jy-table-column
				prop="eState"
				label="运行情况"
				min-width="80"
				show-overflow-tooltip
				:formatter="runStatusFil"
			></jy-table-column>
			<jy-table-column prop="dFromLine" label="是否偏离" width="80">
				<template slot-scope="scope">{{ scope.row.dFromLine | dFromLine }}</template>
			</jy-table-column>
			<jy-table-column prop="noDeviations" label="偏离值" width="80"></jy-table-column>
			<jy-table-column
				prop="verify"
				label="审核状态"
				min-width="110"
				show-overflow-tooltip
				:formatter="auditStatusFil"
			></jy-table-column>
			<jy-table-column prop="remark" label="备注" min-width="120" show-overflow-tooltip></jy-table-column>
			<jy-operate width="280" :list="butList"></jy-operate>
		</jy-table>
		<jy-pagination
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="pageIndex"
			:page-size="pageSize"
			:total="total"
		></jy-pagination>
		<addUpdate ref="addUpdate" v-on:updateList="getList"></addUpdate>
		<detail ref="detail"></detail>
		<site ref="site"></site>
		<track-replay ref="trackReplay"></track-replay>
	</div>
</template>
<script>
import { btnMixins } from "@/common/js/button.mixin";
import addUpdate from "@/components/pages/admin/roadBillInfo/management/addUpdate.vue";
import detail from "@/components/pages/admin/roadBillInfo/management/detail.vue";
import site from "@/components/pages/admin/roadBillInfo/management/site.vue";
import trackReplay from "@/components/pages/admin/common/trackReplay/trackReplay";
import { getAuditStatus, getRunStatus } from "@/common/js/wordbook.js";
export default {
	data() {
		let auditStatus = getAuditStatus();
		let runStatus = getRunStatus();
		return {
			btnMenuId: "scheduleRoadBillInfoManagement",
			butList: [],
			dataList: [],
			auditStatus,
			runStatus,
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			formData: {
				oDate: "",
				routeIds: [],
				customNo: "",
				vehicleNo: "",
				driverNa: "",
				eState: "",
				verify: "",
				sourceT: ""
			},
			routeList: [],
			selectList: [],
			routeTypeList: [
				{
					name: "营运路单",
					dataType: "1"
				},
				{
					name: "非营运路单",
					dataType: "2"
				}
			]
		};
	},
	filters: {
		dFromLine(val) {
			if (val == "0") {
				return "正常";
			} else if (val == "1") {
				return "偏离";
			}
		}
	},
	mixins: [btnMixins],
	components: { addUpdate, detail, site, trackReplay },
	created() {
		this.setButList();
		this.getRouteList();
	},
	activated() {
		console.log(this.$route, "----------1");
		if (this.$route.params.driverNa) {
			this.$refs.checkForm.resetFields();
			this.formData.driverNa = this.$route.params.driverNa;
			this.formData.routeIds = [this.$route.params.routeId];
			this.formData.sourceT = this.$route.params.sourceT;
			this.formData.oDate = this.$route.params.oDate;
			this.search();
		} else {
			this.getList();
		}
	},
	methods: {
		getList() {
			let url = "/waybillActual/queryPage";
			let params = {
				pageIndex: this.pageIndex,
				pageSize: this.pageSize
			};
			Object.keys(this.formData).forEach(key => {
				let d = this.formData[key];
				if (key == "routeIds") {
					d = d.join(",");
				}
				if (!/^\s*$/.test(d)) {
					params[key] = d;
				}
			});
			this.$http.post(url, params).then(({ detail }) => {
				this.dataList = detail.list;
				this.total = detail.total;
			});
		},
		auditStatusFil(row, column, cellVal) {
			let label = "";
			this.auditStatus.forEach(one => {
				if (one.value == cellVal) {
					label = one.label;
				}
			});
			return label;
		},
		runStatusFil(row, column, cellVal) {
			let label = "";
			this.runStatus.forEach(one => {
				if (one.value == cellVal) {
					label = one.label;
				}
			});
			return label;
		},
		setButList() {
			let list = [
				{
					name: "路单修改",
					fun: row => {
						this.$refs.addUpdate.init(row, 1);
					},
					isShow: row => {
						return this.btnexist("scheduleRoadBillInfoManagementUpdate") && row.verify == "0";
					}
				},
				// {
				//     name: '发车通知',
				//     fun: (row) => {
				//         let url = '/waybillPlan/depNotice'
				//         let option = {
				//             waybillId:row.waybillId,
				//         }
				//         this.$http.post(url,option).then(data=>{
				//             this.$message({
				//                 message: data.msg,
				//                 type: 'success'
				//             })
				//             this.getList()
				//         })
				//     },
				//     isShow: () => { return this.btnexist('scheduleRoadBillInfoManagementDepNotice') }
				// },
				{
					name: "完整信息",
					fun: row => {
						this.$refs.detail.init(row);
					},
					isShow: () => {
						return this.btnexist("scheduleRoadBillInfoManagementView");
					}
				},
				{
					name: "过站明细",
					fun: row => {
						this.$refs.site.init(row);
					},
					isShow: () => {
						return this.btnexist("scheduleRoadBillInfoManagementSite");
					}
				},
				{
					name: "轨迹回放",
					fun: row => {
						this.$refs.trackReplay.init(row, "waybill");
					},
					isShow: () => {
						return this.btnexist("scheduleRoadBillInfoManagementTrack");
					}
				}
			];
			this.butList = list;
		},
		search() {
			this.pageIndex = 1;
			this.getList();
		},
		reset() {
			this.$refs.checkForm.resetFields();
			this.search();
		},
		// 分页
		handleSizeChange(val) {
			this.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.pageIndex = val;
			this.getList();
		},
		// 勾选数据
		selectchange(selection) {
			this.selectList = selection;
		},
		selectallchange(selection) {
			this.selectList = selection;
		},
		addFun() {
			this.$refs.addUpdate.init();
		},
		batchDelete() {
			if (!this.selectList.length) {
				this.$message({
					message: "请选择删除对象",
					type: "warning"
				});
				return;
			}

			this.$confirm("是否删除选中路单?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			})
				.then(() => {
					let waybillIds = this.selectList.map(one => one.waybillId).join(",");
					let url = "/waybillActual/del";
					let params = {
						waybillIds
					};
					this.$http.post(url, params).then(data => {
						this.$message({
							message: data.msg,
							type: "success"
						});
						this.getList();
					});
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "已取消删除"
					});
				});
		},
		getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
			});
		}
	}
};
</script>
