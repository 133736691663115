<template>
	<jy-dialog :title="title" :visible.sync="dialogVisible" type="table" :width="'1000px'" append-to-body>
		<jy-table max-height="595" :data="dataList">
			<jy-table-column type="index" label="序号" width="60" align="center"></jy-table-column>
			<jy-table-column prop="oDate" label="日期" width="100"></jy-table-column>
			<jy-table-column prop="routeNa" label="线路" min-width="100"></jy-table-column>
			<jy-table-column prop="customNo" label="自编号" width="95" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" width="95" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="schemeT" label="方向" width="80" :formatter="schemeTFil"></jy-table-column>
			<jy-table-column prop="siteNu" label="站点编号" min-width="100"></jy-table-column>
			<jy-table-column prop="siteNa" label="站点名称" width="135" show-overflow-tooltip></jy-table-column>
			<jy-table-column prop="aTime" label="进站时间" width="80" :formatter="timeFil"></jy-table-column>
			<jy-table-column prop="lTime" label="离站时间" width="80" :formatter="timeFil"></jy-table-column>
			<jy-table-column prop="stopTime" label="停留时间(秒)" min-width="80"></jy-table-column>
			<jy-table-column prop="spd" label="进站速度(km/h)" width="80"></jy-table-column>
		</jy-table>
	</jy-dialog>
</template>
<script>
export default {
	data() {
		return {
			title: "过站明细",
			dialogVisible: false,
			waybillId: "",
			dataList: []
		};
	},
	methods: {
		init(option) {
			this.waybillId = option.waybillId;
			this.dialogVisible = true;
			this.getList(option);
		},
		schemeTFil(row, column, cellVal) {
			if (cellVal == "1") {
				return "上行";
			} else if (cellVal == "2") {
				return "下行";
			} else {
				return "环形";
			}
		},
		timeFil(row, column, cellVal) {
			if (cellVal) {
				return cellVal.split(" ")[1];
			} else {
				return "";
			}
		},
		getList(option) {
			let url = "/waybillActual/waybillDetail";
			let params = {
				waybillId: this.waybillId
			};
			this.$http.post(url, params).then(data => {
				data.detail.forEach((item, k) => {
					data.detail[k] = {
						...option,
						...item
					};
				});
				this.dataList = data.detail;
			});
		}
	}
};
</script>
